class URLParams {
  static buildURLParams( params, options = {}) {
    const result = new URLSearchParams();
    const mappers = options.mappers || {};
    let value;

    Object.keys( params ).forEach(( key ) => {
      value = params[key];

      if ( mappers[key] || mappers[key] === 0 ) {
        value = mappers[key]( value );
      }

      if ( value === false ) {
        result.set( key, false );
      } else if ( value || value === 0 ) {
        result.set( key, value );
      }
    });

    return result.toString();
  }

  constructor() {
    this.params = new URLSearchParams( window.location.search );
    this.pathname = null;
  }

  read( key ) {
    return this.params.has( key ) ? this.params.get( key ) : undefined;
  }

  mread( keys ) {
    const result = {};

    keys.forEach(( key ) => {
      result[key] = this.read( key );
    });

    return result;
  }

  setPath( newPath ) {
    this.pathname = newPath;
  }

  sync( params ) {
    Object.keys( params ).forEach(( key ) => {
      if ( params[key] === false ) {
        this.params.set( key, false );
      } else if ( params[key]) {
        this.params.set( key, params[key]);
      } else {
        this.params.delete( key );
      }
    });

    let url = this.pathname || window.location.pathname;

    if ( this.params.toString() !== '' ) {
      url += `?${this.params}`;
    }

    window.history.replaceState( window.history.state, '', url );
  }
}

export default URLParams;
